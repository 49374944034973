import { Grid, Menu, MenuItem, Portal, Snackbar, Tooltip, Typography } from '@material-ui/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Alert as ReferaAlert, Toast, Dialog } from '@refera/ui-web'
import { Cards } from '@refera/ui-icons'
import Theme from '@refera/ui-core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Alert, AlertTitle } from '@material-ui/lab'
import { navigate, useParams } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'

import { getClassification } from '_/modules/classification/actions'
import { Danger } from 'iconsax-react'
import DropdownIcon from '_assets/icons/ic-dropdown.svg'
import Button from '_components/button'
import Chip from '_components/chip'
import ConfirmationModal from '_components/confirmation-modal'
import AddCategoryModal from '_components/modal/add-category-modal'
import AddProviderModal from '_components/modal/add-provider'
import CancelModal from '_components/modal/cancel-modal'
import DialogModal, { SUCCESS_MODAL, WARNING_MODAL } from '_components/modal/modal-dialog'
import ReactivateModal from '_components/modal/reactivate-modal'
import Priority from '_components/priority'
import Select from '_components/select'
import IconButton, { ICON } from '_components/svg/icon-button'
import useCanDoButtonAction from '_hooks/use-can-do-button-action'
import useFetchCall from '_hooks/use-fetch-call'
import useIsCurrentStepStatusCanceled from '_hooks/use-is-current-step-status-canceled'
import useRolePermission from '_hooks/use-role-permission'
import useToggle from '_hooks/use-toggle'
import { getAgencyResponsible } from '_modules/agency/actions'
import { currentAgencyResponsibleSelector } from '_modules/agency/selectors'
import { userSelector } from '_modules/authentication/selectors'
import {
  createOpenProposeBudget,
  createPropose,
  deleteBudget,
  DELETE_BUDGET,
  getStepStatusLog,
} from '_modules/budget/actions'
import {
  getBudgetByIdSelector,
  getCurrentInnerBudgetSelector,
  getStepStatusLogSelector,
} from '_modules/budget/selectors'
import { getHistoryLogs } from '_modules/history-logs/actions'
import { getMessageOptions, getMessagesByServiceOrderId } from '_modules/messages/actions'
import { getMessagesSelector } from '_modules/messages/selector'
import {
  duplicateServiceOrder,
  DUPLICATE_SERVICE_ORDER,
  getPaymentAnticipationInfo,
  getTradesmanById,
  updateServiceOrder,
  UPDATE_SERVICE_ORDER,
  updateServiceOrderBusinessFront,
  getActiveBudget,
  updateServiceOrderFlowsOptions,
  getServiceOrder,
  verifyWhatsappUpdate,
  getReactivateButton,
} from '_modules/service-orders/actions'
import {
  agencyServiceOrderFlows,
  currentServiceOrderSelector,
  isGetDuplicateServiceOrderLoadingSelector,
  getReactivateButtonSelector,
} from '_modules/service-orders/selectors'
import { classificationsSelector } from '_/modules/classification/selectors'
import { getReasonsToCancel } from '_modules/utils/actions'
import { cancelReasonsSelector } from '_modules/utils/selectors'
import { getPaymentAdvance } from '_modules/paymentAdvance/actions'
import { ORDER_STATUS_LABELS, ROUTES, SERVICE_ORDER_VALUES } from '_utils/constants'
import { BUTTONS_ACTION, INTERMEDIARY_STATUS } from '_utils/constants/service-order'
import PaymentAdvanceApproval from '_views/finance/payment-advance'

import ScheduleModal from '../schedule-modal'
import HelpForm from '../help/help-form'
import HelpList from '../help/help-list'
import HelpView from '../help/help-view'
import JustifiedDelayForm from '../justified-delay/justified-delay-form'
import CompleteCanceledServiceOrderModal from '../modal/complete-canceled-service-order-modal'
import PaymentAnticipationForm from '../payment-anticipation/payment-anticipation-form'

import useStyles from './styles'
import { useToast } from '_/hooks/use-toast'
import { useDialog } from '_/hooks/use-dialog'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'

const HEADER_VALUES = [
  SERVICE_ORDER_VALUES.ID,
  SERVICE_ORDER_VALUES.EXTERNAL_ID,
  SERVICE_ORDER_VALUES.CATEGORY,
  SERVICE_ORDER_VALUES.PRIORITY,
  SERVICE_ORDER_VALUES.FLOW,
  SERVICE_ORDER_VALUES.CLASSIFICATION,
  SERVICE_ORDER_VALUES.RESPONSIBLE,
  'PERSON_PAYING_DATA',
]

const SELECT_EMPTY_VALUE = [{ value: '', label: 'Sem items' }]

const Header = ({
  isEditing,
  handleGetServiceOrderActive,
  handleAnnotationsButtonClick,
  handleShowComments,
  setLoading,
}) => {
  const dispatch = useDispatch()
  const { serviceOrderId } = useParams()
  const cancelReasons = useSelector(cancelReasonsSelector)
  const serviceOrder = useSelector(currentServiceOrderSelector)
  const getMessages = useSelector(getMessagesSelector)
  const serviceOrderResponsible = useSelector(currentAgencyResponsibleSelector)
  const currentInnerBudgetId = useSelector(getCurrentInnerBudgetSelector)
  const user = useSelector(userSelector)
  const agencyFlows = useSelector(agencyServiceOrderFlows)
  const reactivateButton = useSelector(getReactivateButtonSelector)

  const canClaimWarranty = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.CLAIM_WARRANTY,
  })

  const [isModalOpen, handleModal] = useToggle()
  // const [isPayerModalOpen, setPayerModalOpen] = useToggle()
  const [isCancelModalOpen, handleCancelModal] = useToggle()
  const [isDuplicateModalOpen, handleDuplicateModal] = useToggle()
  const [isReactivateModalOpen, handleReactivateModal] = useToggle()
  const [isScheduleVisitOpen, handleScheduleVisit] = useToggle()
  const isCanceled = useIsCurrentStepStatusCanceled()

  const styles = useStyles({ isEditing, isCanceled })
  const { isAdmin, isIntermediary, checkUserPermission } = useRolePermission()

  const { showToast } = useToast()

  const [openHelpList, toggleHelpList] = useToggle()
  const [openHelpForm, toggleHelpForm] = useToggle()
  const [openHelpView, toggleHelpView] = useToggle()
  const [isCompleteServiceOrderModalOpen, toggleCompleteServiceOrderModal] = useToggle()
  const [helpObject, setHelpObject] = useState(null)
  const [paymentAnticipationForm, togglePaymentAnticipationForm] = useToggle()
  const [openJustifiedDelayForm, toggleJustifiedDelayForm] = useToggle()

  const { budgetId } = useParams()

  const budget = useSelector(getBudgetByIdSelector(budgetId))
  const activeBudget = useSelector(getBudgetByIdSelector(serviceOrder.activeBudget))
  const stepStatusLog = useSelector(getStepStatusLogSelector)
  const [anchorEl, setAnchorEl] = useState(null)

  const [openTooltip, setOpenTooltip] = useState(false)
  const [openProposal, setOpenProposal] = useState(false)
  const [openProposalConf, setOpenProposalConf] = useToggle()
  const [cancelProposalConf, setCancelProposalConf] = useToggle()
  const [modalDialog, setModalDialog] = useState({ isOpen: false, subTitle: '' })
  const [permissionModal, setPermissionModal] = useState({ isOpen: false, subTitle: '' })
  const [isAddProviderOpen, setAddProviderOpen] = useToggle()
  const [paymentAdvanceModalOpen, setPaymentAdvanceModalOpen] = useState(false)
  const [paymentAdvanceItem, setPaymentAdvanceItem] = useState(null)
  const [successSnackbar, setSuccessSnackbar] = useState() // SNACKBAR POP-UP
  const [subject, setSubject] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [isClickedCreateInnerBudget, setIsClickedCreateInnerBudget] = useState(false)
  const { showDialog, closeDialog } = useDialog()

  const openDropdown = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
    setOpenTooltip(false)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  function handleChangeDuplicateModal() {
    checkUserPermission(
      PERMISSIONS.DUPLICATE_SERVICE_ORDER,
      PERMISSIONS_ACTIONS.ADD,
      handleDuplicateModal
    )
  }

  function handleChangeHandleCancelModal() {
    checkUserPermission(
      PERMISSIONS.CANCEL_REACTIVATE_SERVICE_ORDER,
      PERMISSIONS_ACTIONS.ADD,
      handleCancelModal
    )
  }

  function handleChangeHandleReactivateModal() {
    checkUserPermission(
      PERMISSIONS.CANCEL_REACTIVATE_SERVICE_ORDER,
      PERMISSIONS_ACTIONS.ADD,
      handleReactivateModal
    )
  }

  const handleExecutionScreen = () => {
    setAnchorEl(null)
    navigate(`/chamado/${serviceOrderId}/orcamento/${budgetId}/execucao`)
  }
  const handleOpenProposal = async () => {
    setAnchorEl(null)
    if (!openProposalConf) {
      setOpenProposalConf()
    } else {
      setIsLoading(true)
      await Promise.resolve(dispatch(createPropose(serviceOrderId, budgetId)))
        .then(res => {
          setModalDialog({
            isOpen: true,
            subTitle: res?.message,
            type: SUCCESS_MODAL,
            onClick: () => {
              window.location.href = `/chamado/${serviceOrderId}/orcamento/${res?.return}`
            },
          })
        })
        .catch(res => {
          setModalDialog({
            isOpen: true,
            subTitle: res?.message,
            type: WARNING_MODAL,
          })
        })
      setOpenProposalConf(false)
      setOpenProposal(true)
      setIsLoading(false)
    }
  }
  const noCategory = useCallback(() => {
    setModalDialog({
      isOpen: true,
      subTitle: 'Selecione antes a Categoria do chamado.',
      type: WARNING_MODAL,
    })
    setOpenProposal(true)
  }, [])

  const handleAddProvider = () => {
    setAddProviderOpen()
    setAnchorEl(null)
  }
  const handleCancelProposal = async () => {
    setAnchorEl(null)
    if (!cancelProposalConf) {
      setCancelProposalConf()
    } else {
      await Promise.resolve(dispatch(deleteBudget(serviceOrderId, budgetId))).then(() =>
        navigate(`${ROUTES.SERVICE_ORDER}/${serviceOrderId}`)
      )
      window.location.reload()
    }
  }

  const handleApprovalConf = useCallback(() => {
    setAnchorEl(null)
    navigate(
      `${ROUTES.SERVICE_ORDER}/${serviceOrderId}/orcamento/${budgetId}/comprovante-aprovacao`
    )
  }, [budgetId, serviceOrderId])

  const handleGetMessages = useCallback(() => {
    dispatch(getMessagesByServiceOrderId(serviceOrderId))
  }, [dispatch, serviceOrderId])

  const handleGetAnticipationPayments = useCallback(() => {
    dispatch(getPaymentAdvance(serviceOrderId))
  }, [dispatch, serviceOrderId])

  // ----------------------------- HELP MODAL
  const [tradesmanList, setTradesmanList] = useState([])

  const getAllTradesman = async () => {
    const IDList = []

    serviceOrder?.budget
      ?.map(x => {
        return x?.tradesman
      })
      .map(tradesman => {
        if (!IDList.includes(tradesman?.id)) {
          IDList.push(tradesman?.id)
          setTradesmanList([...tradesmanList, tradesman])
        }
        return null
      })

    if (serviceOrder?.tradesman > 0) {
      await Promise.resolve(dispatch(getTradesmanById(serviceOrder?.tradesman))).then(res => {
        const tradesman = {
          id: serviceOrder?.tradesman,
          name: res?.user?.name,
          phone: res?.user?.phone,
        }
        setTradesmanList([...tradesmanList, tradesman])
      })
    }
  }

  useEffect(() => {
    getAllTradesman()
  }, [])

  // -----------------------------

  const handleSelectResponsible = useCallback(
    event => {
      const { value: data, name } = event.target

      if (name === 'priority' && serviceOrder?.toJS().businessFront?.name === 'emergency') {
        return handleShowModal(
          'Não é possível alterar a prioridade quando o chamado está no fluxo Emergência.'
        )
      }

      const values = {
        [name]: data,
        datetimeBudgetOrderSelected: new Date(),
      }
      // dispatch(updateServiceOrder({ [name]: data }))
      return Promise.resolve(dispatch(updateServiceOrder(values))).then(handleGetServiceOrderActive)
    },
    [dispatch, agencyFlows]
  )

  const handleSelectClassification = useCallback(
    event => {
      const { value: data } = event.target
      dispatch(updateServiceOrder({ classification: data }))
    },
    [dispatch]
  )

  const handleShowModal = useCallback(message => {
    setModalDialog({
      isOpen: true,
      subTitle: message,
      type: WARNING_MODAL,
    })
    setOpenProposal(true)
  }, [])

  const handleSelectFlow = useCallback(async event => {
    const { value: data } = event.target

    setLoading(true)
    await dispatch(
      updateServiceOrderBusinessFront(serviceOrderId, {
        business_front: data,
      })
    )
      .then(() => {
        handleHistory()
        dispatch(getStepStatusLog(serviceOrderId))
        data === 'emergency' && dispatch(getServiceOrder(serviceOrderId))
      })
      .catch(error => {
        showDialog({
          labelApprove: 'Ok',
          onApprove: closeDialog,
          subject: 'Atenção!',
          description: error?.message,
          type: 'warning',
        })
      })
    setLoading(false)
  }, [])

  const isNonRefusedBudgetItem = budgetItem => {
    return !budgetItem?.isOriginal && budgetItem?.refused === false && budgetItem?.released === true
  }

  const hasNonRefusedBudget = useMemo(
    () => serviceOrder?.budget?.toJS()?.some(isNonRefusedBudgetItem),
    [serviceOrder?.budget]
  )

  const firstCategory = useMemo(() => serviceOrder?.category.toJS()[0], [serviceOrder?.category])
  const priority = useMemo(() => serviceOrder?.priority, [serviceOrder?.priority])

  const handleHistory = useCallback(() => {
    dispatch(getHistoryLogs(serviceOrderId))
  }, [dispatch, serviceOrderId])

  const handleNavigateToBudgetView = useCallback(
    proposeId => {
      const currentBudgetId = proposeId ?? currentInnerBudgetId
      if (activeBudget?.proposeOpened && activeBudget.proposeSolution) {
        navigate(`/chamado/${serviceOrderId}/orcamento/${activeBudget?.id}/`)
      } else if (currentBudgetId) {
        navigate(`${ROUTES.BUDGET_VIEW}/${currentBudgetId}`)
      }
    },
    [currentInnerBudgetId, serviceOrderId]
  )

  const handleCreateOpenProposal = useCallback(() => {
    setIsClickedCreateInnerBudget(true)

    if (!firstCategory || !priority) {
      const subTitle = `Selecione antes a ${
        !firstCategory ? 'categoria' : 'prioridade'
      } do chamado.`

      setModalDialog({
        isOpen: true,
        subTitle,
        type: WARNING_MODAL,
      })
      setOpenProposal(true)
      return
    }

    if (activeBudget?.proposeOpened && activeBudget?.diagnosis) {
      navigate(`/chamado/${serviceOrderId}/orcamento/${activeBudget?.id}/`)
      return
    }

    if (!hasNonRefusedBudget) {
      dispatch(createOpenProposeBudget(serviceOrderId)).then(proposebudgetId => {
        handleHistory()
        handleNavigateToBudgetView(proposebudgetId)
      })
      return
    }

    setModalDialog({
      isOpen: true,
      subTitle:
        'Você não pode executar esta ação porque há um outro orçamento ativo vinculado a este chamado.',
      type: WARNING_MODAL,
    })
    setIsClickedCreateInnerBudget(false)
  }, [
    dispatch,
    firstCategory,
    priority,
    hasNonRefusedBudget,
    serviceOrder?.budget,
    serviceOrderId,
    handleNavigateToBudgetView,
    handleHistory,
  ])

  const renderValues = useMemo(() => {
    return serviceOrder?.toJS().category.map(item => item.id)
  }, [firstCategory, serviceOrder])

  const handleCategories = useCallback(
    categories => {
      Promise.resolve(
        dispatch(
          updateServiceOrder({
            category: categories,
          })
        )
      )
        .then(() => {
          showToast({ type: 'success' })
        })
        .catch(() => {
          showToast({ type: 'error' })
        })
    },
    [dispatch, handleModal]
  )

  const handleUpdateCategory = useCallback(() => {
    if (isModalOpen) {
      handleModal()
    }
  }, [handleModal, isModalOpen])

  const handleListHelpObject = object => {
    if (object) {
      navigate(`/pedidos-ajuda/tramites/${object?.details.id}/`)
    }
    toggleHelpList()

    if (object?.answer?.length > 0 && object?.resolved) {
      toggleHelpView()
    } else {
      toggleHelpForm()
    }
  }

  const handleOpenAdvancePaymentModal = (opening = false) => {
    if (opening === true) {
      toggleHelpList()
    }
    setPaymentAdvanceModalOpen(() => !paymentAdvanceModalOpen)
  }

  const handleGoToPayerData = useCallback(() => {
    navigate(`${ROUTES.SERVICE_ORDER}/${serviceOrderId}/orcamento/${budgetId}/dados-pagador`)
  }, [serviceOrderId, budgetId])

  const handleGoToOwnerData = useCallback(() => {
    dispatch(verifyWhatsappUpdate(serviceOrderId))
    navigate(`${ROUTES.SERVICE_ORDER}/${serviceOrderId}/dados-proprietario`)
  }, [serviceOrderId])

  const handleListJustifiedObject = object => {
    if (object) {
      setHelpObject(object)
    }

    toggleHelpList()

    if (object?.answer?.length > 0 && object?.resolved) {
      toggleHelpView()
    } else {
      toggleJustifiedDelayForm()
    }
  }

  const handleGetPaymentInfoRejected = useCallback(error => {
    setModalDialog({
      isOpen: true,
      subTitle: error || 'Ocorreu um erro ao realizar a operação',
      type: WARNING_MODAL,
    })
    setOpenProposal(true)
  }, [])
  const handlePaymentAnticipationForm = useCallback(
    object => {
      if (object) {
        setHelpObject(object)
      }

      toggleHelpList()

      if (object?.answer?.length > 0 && object?.resolved) {
        toggleHelpView()
      } else {
        dispatch(getPaymentAnticipationInfo(serviceOrderId))
          .then(togglePaymentAnticipationForm)
          .catch(handleGetPaymentInfoRejected)
      }
    },
    [dispatch, serviceOrderId, togglePaymentAnticipationForm, handleGetPaymentInfoRejected]
  )

  useEffect(() => {
    dispatch(
      getClassification({
        pageSize: 1000,
        active: 'True',
        agency: serviceOrder.agency?.get('id'),
        classificationType: 'auto_responsible_selection',
      })
    )
  }, [])

  useEffect(() => {
    dispatch(getActiveBudget(serviceOrder.get('id')))
  }, [serviceOrder.get('id')])

  useEffect(() => {
    if (!reactivateButton) {
      dispatch(getReactivateButton(serviceOrder.get('id')))
    }
  }, [reactivateButton, serviceOrder.get('id')])

  const classifications = useSelector(classificationsSelector)
  const hasClassification = classifications.length > 0

  const [isUpdatingServiceOrder] = useFetchCall(UPDATE_SERVICE_ORDER.ACTION, handleUpdateCategory)
  const renderTableCell = useCallback(
    key => {
      switch (key) {
        case SERVICE_ORDER_VALUES.ID:
          return (
            <Typography key={`cell-${key}`} className={styles.text} color="primary">
              {serviceOrder?.[key] ? `#${serviceOrder?.[key]}` : ''}
            </Typography>
          )

        case SERVICE_ORDER_VALUES.EXTERNAL_ID: {
          if (serviceOrder?.externalId) {
            if (serviceOrder?.externalId.length > 7) {
              return (
                <Tooltip title={serviceOrder?.externalId}>
                  <button type="button" key={`cell-${key}`} className={styles.chipId}>
                    {serviceOrder?.externalId.slice(0, 6)}...
                  </button>
                </Tooltip>
              )
            }
            return (
              <button type="button" key={`cell-${key}`} className={styles.chipId}>
                {serviceOrder?.externalId}
              </button>
            )
          }

          return null
        }

        case SERVICE_ORDER_VALUES.ORDER_STATUS: {
          return (
            <Typography key={`cell-${key}`} className={styles.text} noWrap>
              {ORDER_STATUS_LABELS[serviceOrder?.[key]]}
            </Typography>
          )
        }
        case SERVICE_ORDER_VALUES.CATEGORY:
          if (isEditing) {
            const categories = serviceOrder?.category
            if (categories?.size > 1) {
              const mainCategoryName = categories.first()?.get('name') ?? 'Categoria'
              const extraCategories = categories.slice(1)
              const subcategories = extraCategories.reduce((acc, curr, index) => {
                const last = serviceOrder.category.size - 2 === index
                return `${acc} ${curr.get('name')}${last ? '' : ','}`
              }, '')
              return (
                <Grid className={styles.category} key={`cell-${key}`}>
                  <Button
                    key={key}
                    className={styles.button}
                    onClick={() =>
                      checkUserPermission(
                        PERMISSIONS.CATEGORY,
                        PERMISSIONS_ACTIONS.ADD,
                        handleModal
                      )
                    }
                    color="primary"
                    variant="contained"
                    disabled={isCanceled}
                  >
                    {mainCategoryName}
                  </Button>
                  {extraCategories.size > 0 && (
                    <Tooltip arrow title={subcategories} placement="top">
                      <Typography color="primary" className={styles.text}>
                        + {extraCategories.size}
                      </Typography>
                    </Tooltip>
                  )}
                </Grid>
              )
            }
            return (
              <Button
                key={key}
                className={styles.button}
                onClick={() =>
                  checkUserPermission(PERMISSIONS.CATEGORY, PERMISSIONS_ACTIONS.ADD, handleModal)
                }
                color="primary"
                variant="contained"
                disabled={isCanceled}
              >
                <Typography className={styles.text} noWrap>
                  {categories.first()?.get('name') ?? 'Categoria'}
                </Typography>
              </Button>
            )
          }
          return (
            <Chip
              key={`cell-${key}`}
              label={serviceOrder?.category?.first()?.get('name') ?? 'Categoria'}
              className={styles.chip}
            />
          )
        case SERVICE_ORDER_VALUES.PRIORITY: {
          if (isEditing && isAdmin) {
            return (
              <Select
                key={key}
                className={styles.select}
                isPriorityOptions
                name={key}
                value={priority || ''}
                onChange={event =>
                  checkUserPermission(PERMISSIONS.PRIORITY, PERMISSIONS_ACTIONS.ADD, () =>
                    handleSelectResponsible(event)
                  )
                }
                label="Prioridade"
                disabled={isCanceled || isUpdatingServiceOrder}
              />
            )
          }
          return <Priority key={key} value={serviceOrder?.[key]} />
        }

        case SERVICE_ORDER_VALUES.FLOW: {
          const step = serviceOrder?.get('stepStatus')
          const flow = serviceOrder?.toJS().businessFront ? serviceOrder?.toJS().businessFront : {}

          const stepStatusWitchCannotShowFlows = ['triage', 'waiting_payer_data']
          const flowValue = stepStatusWitchCannotShowFlows.includes(step)

          if (agencyFlows?.length > 0) {
            return (
              <Select
                key={key}
                className={styles.select}
                flowsOptions={agencyFlows}
                isFlowsOptionField
                name={key}
                value={flowValue ? '' : flow?.name}
                onChange={event =>
                  checkUserPermission(PERMISSIONS.FLOW, PERMISSIONS_ACTIONS.ADD, () =>
                    handleSelectFlow(event)
                  )
                }
                label="Fluxo"
                disabled={!isAdmin || isCanceled}
              />
            )
          }
          return null
        }

        case SERVICE_ORDER_VALUES.RESPONSIBLE: {
          const id = serviceOrderResponsible?.length
            ? serviceOrder?.responsible?.toJS()?.id || ''
            : ''
          const options = serviceOrderResponsible?.length
            ? serviceOrderResponsible
            : SELECT_EMPTY_VALUE

          return (
            <Select
              key={key}
              className={styles.select}
              options={options}
              name={key}
              value={id}
              onChange={event =>
                checkUserPermission(PERMISSIONS.PRIORITY, PERMISSIONS_ACTIONS.ADD, () =>
                  handleSelectResponsible(event)
                )
              }
              label="Responsável"
              disabled={isCanceled}
            />
          )
        }

        case SERVICE_ORDER_VALUES.CLASSIFICATION:
          if (hasClassification) {
            return (
              <Select
                key={key}
                className={styles.select}
                options={[{ id: null, classificationName: '     ' }, ...classifications]}
                name={key}
                value={serviceOrder?.classification}
                onChange={event =>
                  checkUserPermission(
                    PERMISSIONS.SERVICE_ORDER_CLASSIFICATIONS,
                    PERMISSIONS_ACTIONS.EDIT,
                    () => handleSelectClassification(event)
                  )
                }
                label="Classificação"
              />
            )
          }

          return null

        case 'PERSON_PAYING_DATA': {
          if (budgetId) {
            return (
              <Grid className={styles.category} key={`cell-${key}`}>
                <Button
                  key={key}
                  className={classnames(styles.button, styles.payerDataButton)}
                  onClick={() =>
                    checkUserPermission(
                      PERMISSIONS.EDIT_PAYER_DATA,
                      PERMISSIONS_ACTIONS.ADD,
                      handleGoToPayerData
                    )
                  }
                  color="primary"
                  variant="outlined"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <Grid className={styles.buttonIcon}>
                    <Cards color={Theme.Colors.Primary.Base} />
                  </Grid>
                  <Typography noWrap>Dados do pagador</Typography>
                </Button>
              </Grid>
            )
          }
          return (
            <Grid className={styles.category} key={`cell-${key}`}>
              <Button
                key={key}
                className={classnames(styles.button, styles.payerDataButton)}
                onClick={() =>
                  checkUserPermission(
                    PERMISSIONS.RESPONSIBLE_DATA,
                    PERMISSIONS_ACTIONS.ADD,
                    handleGoToOwnerData
                  )
                }
                color="primary"
                variant="outlined"
              >
                <Typography>Dados dos envolvidos</Typography>
              </Button>
              {isIntermediary && INTERMEDIARY_STATUS.includes(serviceOrder?.stepStatus) && (
                <Button
                  key={key}
                  className={classnames(styles.button, styles.payerDataButton)}
                  onClick={() => handleShowComments()}
                  color="primary"
                  variant="outlined"
                >
                  <Typography>Atendimento</Typography>
                </Button>
              )}
            </Grid>
          )
        }

        default:
          return (
            <Typography key={`cell-${key}`} noWrap>
              {serviceOrder?.[key]}
            </Typography>
          )
      }
    },
    [
      styles.text,
      styles.chip,
      styles.category,
      styles.button,
      styles.select,
      serviceOrder,
      isEditing,
      firstCategory,
      handleModal,
      isCanceled,
      isAdmin,
      isUpdatingServiceOrder,
      priority,
      serviceOrderResponsible,
    ]
  )

  const handleClearSelectedMessage = () => {
    if (helpObject?.answer?.length > 0 && helpObject?.resolved) {
      toggleHelpView()
    } else {
      toggleHelpForm()
    }
    setHelpObject(undefined)
  }

  const onCloseProviderModal = useCallback(
    updatedProvider => {
      if (updatedProvider === 'updatedProvider') {
        setModalDialog({
          isOpen: true,
          subTitle:
            'O prestador selecionado recebeu o orçamento interno e já pode vê-lo em seu App. ' +
            'Ele agora deve abrir tal orçamento, definir garantias e parcelamentos e enviar o orçamento à Refera.',
          type: SUCCESS_MODAL,
          onClick: handleHistory,
        })
        setOpenProposal(true)
      }
      setAddProviderOpen()
    },
    [handleHistory]
  )

  const handleClearSelectedMessageJustified = () => {
    if (helpObject?.answer?.length > 0 && helpObject?.resolved) {
      toggleHelpView()
    } else {
      toggleJustifiedDelayForm()
    }
    setHelpObject(undefined)
  }

  const handleClearPaymentAnticipation = () => {
    if (helpObject?.answer?.length > 0 && helpObject?.resolved) {
      toggleHelpView()
    } else {
      togglePaymentAnticipationForm()
    }
    setHelpObject(undefined)
  }

  const handleBackToHelpList = () => {
    handleGetMessages()
    if (isAdmin) handleGetAnticipationPayments()
    toggleHelpView()
    toggleHelpList()
    setHelpObject(undefined)
  }

  const handleCloseHelpForm = () => {
    toggleHelpList()
    setHelpObject(undefined)
    toggleHelpForm()
  }

  useEffect(() => {
    if (!cancelReasons.size) {
      dispatch(getReasonsToCancel)
    }
  }, [cancelReasons.size, dispatch])

  useEffect(() => {
    if (serviceOrder.agency?.size && !serviceOrderResponsible?.length) {
      const agencyId = serviceOrder.agency?.get('id')
      if (agencyId) {
        dispatch(getAgencyResponsible(agencyId))
      }
      const flow = serviceOrder?.toJS()?.businessFront ? serviceOrder?.toJS()?.businessFront : {}
      const options = serviceOrder?.agency?.toJS()?.flows?.map(option => ({
        value: option?.name,
        label: option?.description,
        disabled: false,
      }))
      if (flow && !options?.some(opt => opt.value === flow.name)) {
        options.push({
          value: flow?.name,
          label: flow?.description,
          disabled: true,
        })
      }
      options?.sort((a, b) => a?.label?.localeCompare(b?.label))
      dispatch(updateServiceOrderFlowsOptions(options))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const handleConfirmDuplication = useCallback(async () => {
    setIsLoading(true)
    dispatch(duplicateServiceOrder())
      .then(() => {
        setTimeout(() => {
          setIsLoading(false)
        }, 500)
      })
      .catch(err => {
        setTimeout(() => {
          const message = err?.message || 'Ocorreu um erro ao duplicar o chamado.'
          showToast({
            type: 'error',
            message,
          })
          setIsLoading(false)
        }, 500)
      })
  }, [])

  const isGetDuplicateServiceOrderLoading = useSelector(isGetDuplicateServiceOrderLoadingSelector)

  const handleDuplicateReject = useCallback(() => {
    setModalDialog({
      isOpen: true,
      subTitle: 'Este chamado encontra-se em uma Etapa que não permite mais sua duplicação.',
      type: WARNING_MODAL,
      isLoading: isGetDuplicateServiceOrderLoading,
    })
    setOpenProposal(true)
  }, [isGetDuplicateServiceOrderLoading])

  useFetchCall(
    DUPLICATE_SERVICE_ORDER.ACTION,
    () => {
      dispatch(getHistoryLogs(serviceOrder.get('id')))
    },
    handleDuplicateReject
  )
  const handleSuccessSnackbar = () => {
    setSuccessSnackbar(!successSnackbar)
  }

  useEffect(() => {
    dispatch(getMessageOptions())
  }, [])

  useEffect(() => {
    setSubject(getMessages?.get('subject'))
  }, [getMessages?.get('subject')])

  const [toastOpen, setToastOpen] = useState(false)
  const [toastInfo, setToastInfo] = useState({})
  const [claimWarrantyModalIsOpen, setClaimWarrantyModalIsOpen] = useState(false)
  const [guaranteeUnauthorized, setGuaranteeUnauthorized] = useState({
    isOpen: false,
    subTitle: 'Este chamado encontra-se em uma Etapa que não permite solicitar garantia.',
    type: WARNING_MODAL,
  })

  const canCompleteServiceOrder = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.COMPLETE_CANCELED_SERVICE_ORDER,
  })

  const sentRatingLink = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.SENT_RATING_LINK,
    isBudgetRelated: true,
  })

  function onClickClaimWarranty() {
    checkUserPermission(PERMISSIONS.REQUEST_WARRANTY, PERMISSIONS_ACTIONS.ADD, onClaimWarranty)
  }

  const onClaimWarranty = useCallback(() => {
    if (canClaimWarranty) setClaimWarrantyModalIsOpen(true)
    else {
      setGuaranteeUnauthorized(prevState => ({
        ...prevState,
        isOpen: true,
      }))
    }
  }, [canClaimWarranty])

  const guaranteeClaimWarranty = useMemo(() => {
    const {
      id,
      property,
      contactNumber,
      companyName,
      category,
      contactName,
      contactEmail,
      agency,
    } = serviceOrder.toJS()
    const { address: street, number, city, uf } = property
    const userId = user.toJS().id

    const args = {
      idD: id,
      'enderecoDo47[addr_line1]': street,
      'enderecoDo47[addr_line2]': number,
      'enderecoDo47[city]': city,
      'enderecoDo47[state]': uf,
      prestador: companyName,
      manutencao: category[0]?.name,
      usuario: userId,
      nomeDo: contactName,
      emailDo23: contactEmail,
      'telefoneDo[full]': contactNumber?.length >= 14 ? contactNumber.slice(3) : contactNumber,
      complemento: property?.complement,
      imobiliaria: agency.name,
    }

    return Object.keys(args)
      .map(arg => args[arg] && `${arg}=${args[arg]}`)
      .join('&')
  }, [serviceOrder, user])

  const onConfirmClaimWarranty = useCallback(() => {
    setClaimWarrantyModalIsOpen(false)
    window.open(
      `https://form.jotform.com/Suporte_Refera/garantias?${guaranteeClaimWarranty}`,
      '_blank'
    )
  }, [guaranteeClaimWarranty])

  const showPostWorkOption = useMemo(() => {
    const step = serviceOrder.get('stepStatus')
    const STEP_STATUS_LIST_ALLOWED = [
      'waiting_finalization_approval',
      'service_finished',
      'service_order_finished',
      'service_finalization_reproved',
    ]

    return STEP_STATUS_LIST_ALLOWED.includes(step)
  }, [serviceOrder.get('stepStatus')])

  const onCancelClaimWarranty = useCallback(() => {
    setClaimWarrantyModalIsOpen(false)
  }, [])

  const redirectToPostWorkPage = useCallback(() => {
    if (serviceOrderId) window.open(`${ROUTES.SERVICE_FINISHED}/${serviceOrderId}`, '_blank')
  }, [serviceOrderId])

  const handleSendRatingRequest = useCallback(() => {
    navigate(`${ROUTES.SEND_RATING_LINK}/${serviceOrder?.id}`)
  }, [serviceOrder])

  const handleGoToListClassification = useCallback(() => {
    navigate(`${ROUTES.SERVICE_ORDER}/${serviceOrder?.id}${ROUTES.CLASSIFICATION_LIST}`)
  }, [serviceOrder])

  const handleNavigateToManageBudgetServices = useCallback(() => {
    if (!budget.proposeOpened) {
      showToast({
        type: 'error',
        message: 'Ação exclusiva de orçamentos internos.',
      })
      return
    }

    if (stepStatusLog?.[0]?.budgetId?.toString() !== budgetId) {
      showToast({
        type: 'error',
        message: 'Esta ação não pode ser executada na etapa atual.',
      })
      return
    }

    navigate(`${ROUTES.SERVICE_ORDER}/${serviceOrder?.id}/orcamento/${budgetId}/servicos`)
  }, [budget, stepStatusLog])

  const renderMenuItems = useMemo(() => {
    const adminItems = [
      !budgetId && {
        label: 'Criar orçamento interno',
        params: {
          onClick: () =>
            checkUserPermission(
              PERMISSIONS.INTERNAL_BUDGETS,
              PERMISSIONS_ACTIONS.ADD,
              handleCreateOpenProposal
            ),
          disabled: isClickedCreateInnerBudget,
        },
      },
      !budgetId && {
        label: 'Visita para orçamento',
        params: {
          onClick: () =>
            checkUserPermission(
              PERMISSIONS.SCHEDULE_BUDGET_VISIT,
              PERMISSIONS_ACTIONS.ADD,
              handleScheduleVisit
            ),
          disabled: null,
        },
      },
      budgetId &&
        sentRatingLink && {
          label: 'Enviar link de avaliação',
          params: {
            onClick: handleSendRatingRequest,
          },
        },
    ]

    const intermediaryItems = [
      canCompleteServiceOrder && {
        label: 'Concluir chamado',
        params: {
          onClick: toggleCompleteServiceOrderModal,
        },
      },
      {
        label: 'Visita para orçamento',
        params: {
          onClick: () =>
            checkUserPermission(
              PERMISSIONS.SCHEDULE_BUDGET_VISIT,
              PERMISSIONS_ACTIONS.ADD,
              handleScheduleVisit
            ),
          disabled: null,
        },
      },
    ]

    const commomItems = [
      ...(budgetId
        ? [
            {
              label: 'Duplicar como orçamento interno',
              params: {
                onClick: () =>
                  checkUserPermission(
                    PERMISSIONS.INTERNAL_BUDGETS,
                    PERMISSIONS_ACTIONS.ADD,
                    handleOpenProposal
                  ),
              },
            },
            {
              label: 'Execução do serviço',
              params: {
                onClick: () =>
                  checkUserPermission(
                    PERMISSIONS.BUDGETS,
                    PERMISSIONS_ACTIONS.READ,
                    handleExecutionScreen
                  ),
              },
            },
          ]
        : []),
      !budgetId && {
        label: 'Duplicar chamado',
        params: {
          onClick: handleChangeDuplicateModal,
        },
      },
      budgetId && {
        label: 'Comprovante de aprovação',
        params: {
          onClick: handleApprovalConf,
        },
      },
      (isCanceled || reactivateButton) && {
        label: 'Reativar chamado',
        params: {
          onClick: handleChangeHandleReactivateModal,
        },
      },
      !isCanceled &&
        !budgetId && {
          label: 'Cancelar chamado',
          params: {
            onClick: handleChangeHandleCancelModal,
          },
        },
      showPostWorkOption && {
        label: 'Resumo pós-obra do pagador',
        params: {
          onClick: redirectToPostWorkPage,
        },
      },
      {
        label: 'Classificações',
        params: {
          onClick: handleGoToListClassification,
        },
      },
    ]

    const requestGuarantee = {
      label: 'Solicitar garantia',
      params: {
        onClick: () =>
          checkUserPermission(
            PERMISSIONS.REQUEST_WARRANTY,
            PERMISSIONS_ACTIONS.ADD,
            onClickClaimWarranty
          ),
      },
    }

    const manageBudgetServices =
      budgetId && isAdmin
        ? {
            label: 'Gerenciar serviços',
            params: {
              onClick: () =>
                checkUserPermission(
                  PERMISSIONS.BUDGET_SERVICES,
                  PERMISSIONS_ACTIONS.READ,
                  handleNavigateToManageBudgetServices
                ),
            },
          }
        : null

    const items = [
      ...commomItems,
      ...(isAdmin ? adminItems : []),
      requestGuarantee,
      manageBudgetServices,
      ...(isIntermediary ? intermediaryItems : []),
    ]

    return items
      .filter(item => item)
      .map(item => <MenuItem {...item.params}>{item.label}</MenuItem>)
  }, [
    budget?.proposeOpened,
    budgetId,
    canCompleteServiceOrder,
    handleAddProvider,
    handleApprovalConf,
    handleCancelModal,
    handleCancelProposal,
    handleCreateOpenProposal,
    handleExecutionScreen,
    handleOpenProposal,
    handleScheduleVisit,
    handleReactivateModal,
    handleNavigateToManageBudgetServices,
    firstCategory,
    isAdmin,
    isIntermediary,
    isCanceled,
    noCategory,
    reactivateButton,
  ])

  const handleCloseToast = () => {
    setToastOpen(false)
    setToastInfo({})
  }

  const handleOpenTooltip = () => {
    setOpenTooltip(true)
  }

  const handleCloseTooltip = () => {
    setOpenTooltip(false)
  }

  return (
    <Grid className={styles.tableContainer} wrap>
      <Grid className={styles.header}>
        <IconButton to={ROUTES.MANAGE_ORDERS} ariaLabel="Voltar à página anterior">
          <ArrowBackIcon />
        </IconButton>
        {HEADER_VALUES.map(key => {
          const cellContent = renderTableCell(key)
          if (cellContent) {
            return (
              <Grid item key={key} className={styles.tableCell}>
                {cellContent}
              </Grid>
            )
          }
          return null
        })}
        <Grid className={styles.buttons}>
          <Tooltip title="Anotações" placement="bottom">
            <IconButton type={ICON.DOCUMENT_TEXT} onClick={handleAnnotationsButtonClick} />
          </Tooltip>
          <Tooltip title="Opções" placement="bottom" open={openTooltip && !openProposalConf}>
            <span>
              <div
                onMouseOver={handleOpenTooltip}
                onFocus={handleCloseTooltip}
                onMouseLeave={handleCloseTooltip}
              >
                <IconButton
                  icon={DropdownIcon}
                  aria-controls={openTooltip ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openTooltip ? 'true' : undefined}
                  onClick={handleClick}
                />
              </div>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openDropdown}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                  style: {
                    marginTop: 40,
                    marginLeft: 8,
                  },
                }}
              >
                {renderMenuItems}
              </Menu>
            </span>
          </Tooltip>
          <ReactivateModal
            open={isReactivateModalOpen}
            onClose={handleReactivateModal}
            handleGetServiceOrderActive={handleGetServiceOrderActive}
          />
        </Grid>
      </Grid>
      <Toast draggable open={toastOpen} autoHideDuration={6000} onClose={handleCloseToast}>
        <ReferaAlert severity={toastInfo.severity} onClose={handleCloseToast}>
          <AlertTitle>{toastInfo.title}</AlertTitle>
          {toastInfo.message}
        </ReferaAlert>
      </Toast>
      {isModalOpen && (
        <AddCategoryModal
          isServiceOrder
          value={renderValues}
          handleModal={() =>
            checkUserPermission(PERMISSIONS.CATEGORY, PERMISSIONS_ACTIONS.ADD, handleModal)
          }
          patchCategories={handleCategories}
          categoriesSO={serviceOrder?.category}
        />
      )}
      {isCancelModalOpen && (
        <CancelModal
          serviceOrderId={serviceOrderId}
          handleModal={handleCancelModal}
          cancelReasons={cancelReasons}
          handleGetServiceOrderActive={handleGetServiceOrderActive}
        />
      )}
      {isDuplicateModalOpen && (
        <ConfirmationModal
          isOpen={isDuplicateModalOpen}
          handleClose={() =>
            checkUserPermission(
              PERMISSIONS.DUPLICATE_SERVICE_ORDER,
              PERMISSIONS_ACTIONS.ADD,
              handleDuplicateModal
            )
          }
          handleConfirmClick={handleConfirmDuplication}
          description="Você tem certeza que deseja duplicar o chamado atual?"
          cancelButtonText="Não"
          confirmButtonText="Sim"
          action={DUPLICATE_SERVICE_ORDER.ACTION}
          disabled={isLoading}
        />
      )}
      {isScheduleVisitOpen && (
        <ScheduleModal
          open={isScheduleVisitOpen}
          onClose={handleScheduleVisit}
          serviceOrder={serviceOrder}
          serviceOrderId={serviceOrderId}
        />
      )}
      {openHelpList && (
        <HelpList
          closeModal={toggleHelpList}
          subjectOption={subject}
          openHelpForm={handleListHelpObject}
          justifiedDelayForm={handleListJustifiedObject}
          paymentAnticipationForm={handlePaymentAnticipationForm}
          tradesmanInfo={tradesmanList}
          setPaymentAdvanceItem={setPaymentAdvanceItem}
          handleOpenAdvancePaymentModal={handleOpenAdvancePaymentModal}
        />
      )}
      {openHelpForm && (
        <HelpForm
          closeModal={handleClearSelectedMessage}
          setSubjectOption={setSubject}
          message={helpObject}
          setMessage={setHelpObject}
          onCancel={handleCloseHelpForm}
        />
      )}
      {openHelpView && (
        <HelpView
          closeModal={handleClearSelectedMessage}
          subjectOption={subject}
          message={helpObject}
          handleBackToHelpList={handleBackToHelpList}
        />
      )}
      {openJustifiedDelayForm && (
        <JustifiedDelayForm
          closeModal={handleClearSelectedMessageJustified}
          // subjectOption={subject}
          message={helpObject}
          setMessage={setHelpObject}
          tradesmanInfo={tradesmanList}
        />
      )}
      {paymentAnticipationForm && (
        <PaymentAnticipationForm closeModal={handleClearPaymentAnticipation} />
      )}
      {openProposalConf ? (
        <ConfirmationModal
          isOpen={openProposalConf}
          handleClose={setOpenProposalConf}
          handleConfirmClick={handleOpenProposal}
          description="Você tem certeza que deseja duplicar este orçamento e criar um orçamento interno baseado nele?"
          cancelButtonText="Não"
          confirmButtonText="Sim"
          disabled={isLoading}
        />
      ) : null}
      {cancelProposalConf && (
        <ConfirmationModal
          isOpen={cancelProposalConf}
          handleClose={setCancelProposalConf}
          handleConfirmClick={handleCancelProposal}
          description="Você tem certeza que deseja cancelar este orçamento interno?"
          cancelButtonText="Não"
          confirmButtonText="Sim"
          action={DELETE_BUDGET.ACTION}
        />
      )}
      {(openProposal || hasNonRefusedBudget) && (
        <DialogModal modalDialog={modalDialog} setModalDialog={setModalDialog} />
      )}
      {!isAdmin && (
        <DialogModal modalDialog={permissionModal} setModalDialog={setPermissionModal} />
      )}
      {!canClaimWarranty && (
        <DialogModal
          modalDialog={guaranteeUnauthorized}
          setModalDialog={setGuaranteeUnauthorized}
        />
      )}
      <AddProviderModal
        onCloseModal={onCloseProviderModal}
        serviceOrder={serviceOrder}
        openPropose
        isOpen={isAddProviderOpen}
      />
      <Dialog
        open={claimWarrantyModalIsOpen}
        type="warning"
        subject="Você tem certeza que deseja solicitar garantia para o chamado?"
        description="Para prosseguir com este procedimento, você será redirecionado para formulário que deverá ser preenchido e enviado para dar inicio ao processo de garantia."
        onApprove={onConfirmClaimWarranty}
        onCancel={onCancelClaimWarranty}
        icon={Danger}
        labelApprove="Sim"
        labelCancel="Não"
      />
      {paymentAdvanceModalOpen && (
        <PaymentAdvanceApproval
          paymentAdvanceItem={paymentAdvanceItem}
          closeModal={handleOpenAdvancePaymentModal}
          setSuccessSnackbar={handleSuccessSnackbar}
        />
      )}
      <CompleteCanceledServiceOrderModal
        isOpen={isCompleteServiceOrderModalOpen}
        handleClose={toggleCompleteServiceOrderModal}
      />
      <Portal>
        <Snackbar
          open={successSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          autoHideDuration={6000}
          onClose={() => setSuccessSnackbar(false)}
        >
          <Alert
            onClose={() => setSuccessSnackbar(false)}
            severity="success"
            variant="filled"
            sx={{ width: '100%' }}
          >
            <Typography>Sua ação foi realizada com sucesso</Typography>
          </Alert>
        </Snackbar>
      </Portal>
    </Grid>
  )
}

export default React.memo(Header)
