import { makeStyles } from '@material-ui/core/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ palette, spacing }) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: spacing(600),
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing(16, 24),
  },
  title: {
    fontSize: spacing(20),
    lineHeight: spacing(20),
    fontWeight: 'bold',
    fontFamily: 'DM Sans',
    color: palette.primary.main,
    padding: 0,
  },
  subtitle: {
    fontWeight: 'bold',
    color: palette.gray[64],
    fontSize: spacing(20),
    flexWrap: 'wrap',
    display: 'flex',
    textAlign: 'center',
    lineHeight: spacing(30),
  },
  content: {
    padding: spacing(16, 24),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(16),
  },
  actions: {
    padding: spacing(12, 24, 16),
  },
  button: {
    width: spacing(90),
    padding: `${spacing(10)} auto`,
  },
  actionsModal: {
    padding: spacing(20),
    gap: spacing(12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputLabel: {
    color: `${Theme.Colors.Grayscale.OneThousand} !important`,
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontWeight: Theme.Typography.FontWeight.Medium,
    fontSize: `${Theme.Typography.FontSize.Tiny}px !important`,
  },
  selectLabel: {
    marginBottom: '4px',
  },
  justificationContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(16, 0),
    gap: spacing(16),
  },
}))
