import React, { useState, useCallback } from 'react'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import * as Modal from '_components/modal/generic-modal'

import { Button, Loader, Dialog as ReferaDialog } from '@refera/ui-web'
import { Danger as InfoDangerIcon } from '@refera/ui-icons'
import { DateTimePicker, MultilineInput, Select } from '_components/inputs'

import useStyles from './styles'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { serviceOrderCurrentSelector } from '_modules/service-orders/selectors'
import moment from 'moment'
import { scheduleExecution } from '_/modules/service-orders/actions'
import { useToast } from '_/hooks/use-toast'
import { getStepStatusLog } from '_/modules/budget/actions'

import classNames from 'classnames'

const defaultDialog = {
  isOpen: false,
  subject: '',
  icon: InfoDangerIcon,
  iconType: 'info',
  description: '',
  labelApprove: 'Ok, entendi',
  onApprove: () => {},
}

const RESCHEDULING_REQUESTER = [
  { value: '', label: 'Selecione' },
  { value: 'client', label: 'Cliente' },
  { value: 'tradesman', label: 'Prestador' },
  { value: 'other', label: 'Outro' },
]

export function ScheduleExecutionModal({ open, onClose, onCancel }) {
  const { showToast } = useToast()
  const [saveDisabled, setSaveDisabled] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dialogInfo, setDialogInfo] = useState(defaultDialog)
  const [modalScheduleConfirm, setModalScheduleConfirm] = useState(false)

  const styles = useStyles()

  const dispatch = useDispatch()
  const serviceOrder = useSelector(serviceOrderCurrentSelector)
  const serviceOrderDatetimeExecutionScheduled = serviceOrder?.get('datetimeExecutionScheduled')
  const serviceOrderDateAndTimeFinish = serviceOrder?.get('dateAndTimeFinish')

  const defaultValues = {
    datetimeExecutionScheduled: serviceOrderDatetimeExecutionScheduled || null,
    dateAndTimeFinish: serviceOrderDateAndTimeFinish || null,
    reschedulingRequester: '',
    reschedulingReason: '',
  }

  const methods = useForm({
    defaultValues,
    mode: 'all',
  })

  const sendScheduleExecution = useCallback(
    async (
      datetimeExecutionScheduled,
      dateAndTimeFinish,
      reschedulingRequester,
      reschedulingReason
    ) => {
      dialogInfo.isOpen && setDialogInfo(defaultDialog)
      setLoading(true)
      dispatch(
        scheduleExecution(serviceOrder?.get('id'), {
          dateAndTime: datetimeExecutionScheduled.toISOString(),
          dateAndTimeFinish: dateAndTimeFinish.toISOString(),
          appointmentType: 'execution',
          reschedulingRequester,
          reschedulingReason,
        })
      )
        .then(() => {
          dispatch(getStepStatusLog(serviceOrder?.get('id'))).then(() => {
            setSaveDisabled(false)
            setLoading(false)
            showToast({
              type: 'success',
            })
            onClose()
          })
        })
        .catch(() => {
          setSaveDisabled(false)
          setLoading(false)
          showToast({
            type: 'error',
            message: 'Houve um erro ao tentar agendar a execução.',
          })
        })
    },
    [
      onClose,
      serviceOrder?.get('id'),
      serviceOrder?.get('requesterRatingFeature'),
      dialogInfo.isOpen,
    ]
  )

  const handleSaveButtonClick = useCallback(
    async data => {
      if (!methods.formState.isDirty) {
        onClose()
        return
      }

      setSaveDisabled(true)
      const datetimeExecutionScheduled = moment(data.datetimeExecutionScheduled)
      const dateAndTimeFinish = moment(data.dateAndTimeFinish)

      if (datetimeExecutionScheduled.isAfter(dateAndTimeFinish)) {
        setSaveDisabled(false)
        showToast({
          type: 'error',
          message:
            'Data de expectativa de finalização do serviço inferior a data do agendamento da execução.',
        })
        return
      }

      if (datetimeExecutionScheduled.isBefore(moment()) || dateAndTimeFinish.isBefore(moment())) {
        setDialogInfo({
          isOpen: true,
          iconType: 'warning',
          icon: InfoDangerIcon,
          labelApprove: 'Sim',
          labelCancel: 'Não',
          subject: 'A data e hora informadas em um dos campos já passaram!',
          description: 'Você confirma esta informação?',
          onApprove: () =>
            sendScheduleExecution(
              datetimeExecutionScheduled,
              dateAndTimeFinish,
              data.reschedulingRequester,
              data.reschedulingReason
            ),
          onCancel: () => {
            setSaveDisabled(false)
            setDialogInfo(defaultDialog)
          },
        })

        return
      }

      await sendScheduleExecution(
        datetimeExecutionScheduled,
        dateAndTimeFinish,
        data.reschedulingRequester,
        data.reschedulingReason
      )
    },
    [methods.formState.isDirty, sendScheduleExecution, onClose]
  )

  return (
    <>
      <Dialog open={open} className={styles.dialog} keepMounted={false}>
        <div key="ScheduleExecutionModalHeader" className={styles.header}>
          <DialogTitle className={styles.title}>Agendar execução</DialogTitle>
        </div>
        <DialogContent className={styles.content}>
          <FormProvider {...methods}>
            <form
              key="scheduleExecutionModalForm"
              id="scheduleExecutionModalForm"
              className={styles.form}
              onSubmit={e => e.preventDefault()}
              noValidate
            >
              <DateTimePicker
                name="datetimeExecutionScheduled"
                label="Agendamento da execução"
                required
              />
              <DateTimePicker
                name="dateAndTimeFinish"
                label="Expectativa de finalização do serviço"
                required
              />
              {serviceOrderDatetimeExecutionScheduled && (
                <div className={styles.justificationContent}>
                  <Select
                    label="Quem solicitou o reagendamento?"
                    name="reschedulingRequester"
                    defaultValue=""
                    options={RESCHEDULING_REQUESTER}
                    required
                    getKey={item => item.value}
                    getValue={item => item.value}
                    getLabel={item => item.label}
                    labelClasses={classNames(styles.inputLabel, styles.selectLabel)}
                    inputProps={{ displayEmpty: true }}
                  />
                  <MultilineInput
                    defaultValue=""
                    name="reschedulingReason"
                    label="Motivo do reagendamento"
                    minRows={8}
                    labelClasses={styles.inputLabel}
                    required
                    inputProps={{ maxLength: 1500 }}
                  />
                </div>
              )}
            </form>
          </FormProvider>
        </DialogContent>
        <DialogActions className={styles.actions}>
          <Button onClick={onCancel} variant="secondary" color="red" className={styles.button}>
            Cancelar
          </Button>
          <Button
            onClick={() => setModalScheduleConfirm(true)}
            className={styles.button}
            type="button"
            disabled={saveDisabled || loading}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      <Modal.Root open={modalScheduleConfirm} onClose={() => setModalScheduleConfirm(false)}>
        <Modal.Content>
          <Modal.WarningIcon />
          <Modal.Text className={styles.subtitle}>
            Caso você confirme, o solicitante será informado sobre a data selecionada, tanto de
            início quanto do fim da execução.
          </Modal.Text>
          <Modal.Text>Tem certeza que deseja fazer esse agendamento?</Modal.Text>
        </Modal.Content>
        <Modal.Actions className={styles.actionsModal}>
          <Modal.ButtonRed onClick={() => setModalScheduleConfirm(false)}>Cancelar</Modal.ButtonRed>
          <Modal.ButtonFullBlue onClick={methods.handleSubmit(handleSaveButtonClick)}>
            Confirmar
          </Modal.ButtonFullBlue>
        </Modal.Actions>
      </Modal.Root>

      {dialogInfo?.isOpen && (
        <ReferaDialog
          open={dialogInfo?.isOpen}
          type={dialogInfo?.iconType}
          icon={dialogInfo?.icon}
          subject={dialogInfo?.subject}
          description={dialogInfo?.description}
          labelApprove={dialogInfo?.labelApprove}
          onApprove={dialogInfo?.onApprove}
          labelCancel={dialogInfo?.labelCancel}
          onCancel={dialogInfo?.onCancel}
        />
      )}
      <Loader hasBackdrop open={loading} label="Aguarde..." />
    </>
  )
}
